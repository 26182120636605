/* Main container for the dashboard */
.dashboard-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transition: all 0.3s ease;
  z-index: 1000;
}
.logout {
    margin-top: auto; /* Az elem a lehető legmesszebb kerül a tetejétől */
    padding: 10px;
    margin-top: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    border-radius: 5px;
}

.logout:hover {
    background-color: #f0f0f0;
    color:black;
}
.sidebar.open {
  width: 60px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar h3 {
  font-size: 24px;
  margin-left: 10px;
  display: inline-block;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.sidebar nav ul li a:hover {
  color: #f0a500;
}

.sidebar nav ul li .menu-icon {
  font-size: 24px;
}

.sidebar nav ul li button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.sidebar nav ul li button .menu-icon {
  font-size: 24px;
  margin-right: 10px;
}

.sidebar.open ul li a span,
.sidebar.open ul li button span {
  display: none; /* Hide the text in the collapsed state */
}

/* Hamburger Menu button */
.menu-btn {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

/* Content area */
.content {
  margin-left: 290px;
  width: calc(100% - 250px);
  transition: all 0.3s ease;
  overflow-y: auto; /* Allows scrolling in the main content area */
  padding: 20px;
  height: 90%;
}

.content.shifted {
  margin-left: 100px;
  width: calc(100% - 60px);
}

/* Mobile Header */
.mobile-header {
  display: none;
  background-color: #333;
  color: white;
  padding: 10px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.mobile-header h3 {
  margin: 0;
  padding-left: 50px; /* Give space for the hamburger */
}

@media (max-width: 768px) {
  .sidebar {
      width: 60px;
      left: -250px;
  }

  .sidebar.open {
      left: 0;
  }

  .content {
    padding-top: 80px;
      margin-left: 10px;
      width: calc(100% - 60px);
    
  padding-bottom: 180px;
  }

  .mobile-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
}
span.menuButton {
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
}